var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"height":"auto","fixed-header":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:`item.actions`,fn:function({ item }){return [(_vm.isPDF)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-file-text mr-2"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]):_vm._e(),(
          _vm.isDetail &&
            _vm.isUpdate &&
            _vm.userRequest &&
            item.statusRequest == 1 &&
            false
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.updateRequest(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-edit-1"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(
          _vm.userRequestAuthorization &&
            _vm.isDetail &&
            (item.statusRequest == 4 || item.statusRequest == 7)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.assingUserRequest(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-clipboard"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.statusRequestName))])]):(_vm.userRequest && _vm.isDetail && item.statusRequest == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detailRequest(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-clipboard"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.statusRequestName))])]):(
          _vm.userValidate &&
            _vm.isDetail &&
            (item.statusRequest == 3 || item.statusRequest == 6)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.assingUserRequest(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-clipboard"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.statusRequestName))])]):_vm._e(),(
          _vm.userRequest &&
            _vm.isDetail &&
            (item.statusRequest == 2 ||
              item.statusRequest == 3 ||
              item.statusRequest == 4 ||
              item.statusRequest == 5 ||
              item.statusRequest == 6 ||
              item.statusRequest == 7 ||
              item.statusRequest == 8 ||
              item.statusRequest == 9 ||
              item.statusRequest == 10)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2"},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye",on:{"click":function($event){return _vm.detailRequest(item)}}})])]}}],null,true)},[_c('span',[_vm._v("Detalle")])]):_vm._e(),(
          _vm.userValidate &&
            _vm.isDetail &&
            (item.statusRequest == 7 ||
              item.statusRequest == 8 ||
              item.statusRequest == 9 ||
              item.statusRequest == 5 ||
              item.statusRequest == 4 ||
              item.statusRequest == 10)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2"},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye",on:{"click":function($event){return _vm.detailRequest(item)}}})])]}}],null,true)},[_c('span',[_vm._v("Detalle")])]):_vm._e(),(
          _vm.userRequestAuthorization &&
            _vm.isDetail &&
            (item.statusRequest == 5 ||
              item.statusRequest == 6 ||
              item.statusRequest == 8 ||
              item.statusRequest == 9 ||
              item.statusRequest == 10)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2"},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye",on:{"click":function($event){return _vm.detailRequest(item)}}})])]}}],null,true)},[_c('span',[_vm._v("Detalle")])]):_vm._e()]}},{key:`item.statusRequestName`,fn:function({ item }){return [_c('ChipTableComponentRequest',{attrs:{"status":item.statusRequest,"statusName":item.statusRequestName}})]}}],null,true)}),_c('ButtonPrintComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }